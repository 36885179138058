import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import ThreeDSecureModal from '../ThreeDSecureModal/ThreeDSecureModal'
import * as piApi from 'api/paymentInstruction'
import * as cardApi from 'api/card'
import * as rulesApi from 'api/rules'
import {
  ChargGroup,
  PAYMENT_STATE,
  PaymentInstruction,
  PaymentInstructionTemplate,
  Set,
} from 'types/paymentInstruction'
import { Charge, RedirectData, ChargeStatus, NextActionType } from 'types/charge'
import { Approver } from 'types/user'
import { pi } from 'lang/definitions'
import * as Sentry from '@sentry/react'
import { useCurrentPayment } from 'stores/Payment'
import { useActiveUser, useDebouncePromise, useFeatureFlags } from 'hooks'
import { useSession } from 'stores/session'
import useSubmitPaymentsStyle from './SubmitPayments.style'
import { Button } from 'antd'

const CHARGE_PING_INTERVAL = 1000

interface SubmitPaymentsProps {
  set?: Set
  requireSignatureSet?: Set
  requireActionSet?: Set
  skipApproverSelection?: boolean
  goToProgressSlide: () => void
  triggerProcessingPaymentsSearch?: boolean
  startPolling: (set: Set) => void
  fetchPIsAndTemplatesForSignatureRequest?: (
    set: Set,
    includeSignatures: boolean
  ) => Promise<{ paymentInstructions: PaymentInstruction[]; paymentInstructionTemplates: PaymentInstructionTemplate[] }>
}

const SubmitPayments = ({
  requireSignatureSet,
  goToProgressSlide,
  skipApproverSelection,
  set,
  startPolling,
  requireActionSet,
}: SubmitPaymentsProps): React.JSX.Element => {
  const {
    state: { user },
    actions: { setRules },
  } = useSession()
  const location = useLocation()

  const { useDynamicPaymentThreshold } = useFeatureFlags()

  const { profileId } = useActiveUser()
  const intl = useIntl()
  const { styles } = useSubmitPaymentsStyle()

  const [numberOfPaymentsToPay, setNumberOfPaymentsToPay] = useState(0)
  const [numberOfPaymentsForSignatureRequest, setNumberOfPaymentsForSignatureRequest] = useState(0)
  const [numberOfPaymentsToApprove, setNumberOfPaymentsToApprove] = useState(0)
  const [is3DSecureShown, setIs3DSecureShown] = useState(false)
  const [redirectData, setRedirectData] = useState<RedirectData>()
  const [progressData, setProgressData] = useState<{
    current: number | undefined
    total: number
  }>()
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [chargeId, setChargeId] = useState('')

  const {
    state: {
      selectedApprovers,
      selectedPayments,
      numberOfSignaturesRequired,
      signatureOrder,
      needConfirmation,
      checkedConfirmation,
      approveSet,
      approveAndPaySet,
      readySet: readySetFromStore,
    },
    actions: { setNumberOfPaymentsSentToApprovers, setSubmitError, setNumberOfApprovedPayments },
  } = useCurrentPayment()

  const readySet = set ? set : readySetFromStore

  const isPaymentReview = location.pathname === '/app/payments/review'

  useEffect(() => {
    setNumberOfPaymentsForSignatureRequest(selectedPayments.length)
  }, [requireSignatureSet])

  useEffect(() => {
    let amountToApprove = 0
    let amountToPay = 0

    if (readySet && !isPaymentReview) {
      const { paymentInstructionIds, paymentInstructionTemplateIds } = readySet
      amountToPay += paymentInstructionIds!.length + paymentInstructionTemplateIds!.length
    }

    if (approveAndPaySet) {
      const { paymentInstructionIds, paymentInstructionTemplateIds } = approveAndPaySet

      amountToPay += paymentInstructionIds!.length + paymentInstructionTemplateIds!.length
    }

    if (approveSet) {
      const { paymentInstructionIds, paymentInstructionTemplateIds } = approveSet

      amountToApprove += paymentInstructionIds!.length + paymentInstructionTemplateIds!.length
    }

    setNumberOfPaymentsToApprove(amountToApprove)
    setNumberOfPaymentsToPay(amountToPay)
  }, [readySet, approveSet, approveAndPaySet])

  useEffect(() => {
    const numberOfSelectedApprovers = selectedApprovers?.length || 0
    if (needConfirmation && !checkedConfirmation) {
      setIsSubmitButtonDisabled(true)
    } else {
      if (numberOfPaymentsForSignatureRequest > 0 || numberOfPaymentsToApprove > 0) {
        if (signatureOrder === 'parallel') {
          const shouldSelectMoreApprovers =
            !skipApproverSelection && numberOfSelectedApprovers < (numberOfSignaturesRequired || 0)
          setIsSubmitButtonDisabled(shouldSelectMoreApprovers)
        } else if (signatureOrder === 'sequential') {
          setIsSubmitButtonDisabled(numberOfSelectedApprovers === 0)
        }
      } else {
        setIsSubmitButtonDisabled(numberOfPaymentsToPay === 0)
      }
    }
  }, [
    selectedApprovers,
    numberOfSignaturesRequired,
    signatureOrder,
    numberOfPaymentsToPay,
    checkedConfirmation,
    needConfirmation,
    numberOfPaymentsForSignatureRequest,
    numberOfPaymentsToApprove,
  ])

  const timeout = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const pingCharge = async (chargeId: string): Promise<Charge> => {
    const response = await cardApi.pingCharge(chargeId)

    if (
      response.status === ChargeStatus.FAIL ||
      response.status === ChargeStatus.SUCCESS ||
      response.status === ChargeStatus.AUTHORIZED
    ) {
      Sentry.addBreadcrumb({
        category: 'payments',
        message: `Payment with charge id ${response.id} status changed to ${response.status}`,
        level: 'info',
      })
      return response
    } else {
      await timeout(CHARGE_PING_INTERVAL)
      return await pingCharge(chargeId)
    }
  }

  const handleCharge = async (
    charge: Charge,
    currentChargeIndex: number,
    numberOfRequiresActionCharges: number
  ): Promise<Charge> => {
    const { id, nextAction } = charge
    const { redirect } = nextAction!

    setProgressData({
      current: currentChargeIndex + 1,
      total: numberOfRequiresActionCharges,
    })

    setRedirectData(redirect)
    setChargeId(id)
    setIs3DSecureShown(true)

    const processedCharge = await pingCharge(id)

    setRedirectData(undefined)
    setIs3DSecureShown(false)
    setChargeId('')

    return processedCharge
  }

  const handleChargeForDPT = async (charge: Charge, numberOfRequiresActionCharges: number): Promise<Charge> => {
    const { id, nextAction } = charge
    const { redirect } = nextAction!

    setProgressData({
      current: undefined,
      total: numberOfRequiresActionCharges,
    })

    setRedirectData(redirect)
    setChargeId(id)
    setIs3DSecureShown(true)

    const processedCharge = await pingCharge(id)

    setRedirectData(undefined)
    setChargeId('')
    setIs3DSecureShown(false)

    return processedCharge
  }

  const processChargedSet = async (set: Set) => {
    let charges: Charge[] = []
    if (useDynamicPaymentThreshold) {
      const { charges: fetchedCharges } = await cardApi.searchCharges({
        kind: 'charge',
        setId: [set.id!],
        status: 'REQUIRES_ACTION',
      })

      charges = [...fetchedCharges]
    } else {
      charges = set.chargeGroups!.map((chargeGroup: ChargGroup) => chargeGroup.charge)
    }

    const requiresActionCharges = charges.filter(
      (charge: Charge) =>
        charge && charge.status === ChargeStatus.REQUIRES_ACTION && charge.nextAction?.type === NextActionType.REDIRECT
    )

    for (let index = 0; index < requiresActionCharges.length; index++) {
      useDynamicPaymentThreshold
        ? await handleChargeForDPT(requiresActionCharges[index], requiresActionCharges.length)
        : await handleCharge(requiresActionCharges[index], index, requiresActionCharges.length)
    }
  }

  useEffect(() => {
    if (!requireActionSet) {
      return
    }

    try {
      void processChargedSet(requireActionSet)
    } catch (err) {
      Sentry.captureException(err)
      setSubmitError(true)
    }
  }, [requireActionSet])

  const chargeSetAsync = async (set: Set) => {
    try {
      await piApi.chargeSetAsync(set.id!)

      startPolling(set)
    } catch (error) {
      Sentry.captureException(error)
      throw new Error('Charge set error')
    }
  }

  const sendSignatureRequestToApprovers = async (): Promise<void> => {
    const approvers = selectedApprovers.map(
      (
        approver: Approver
      ): {
        userId: string
        email: string
      } => ({
        userId: approver.id,
        email: approver.email,
      })
    )

    if (selectedPayments && selectedPayments?.length) {
      try {
        setNumberOfPaymentsSentToApprovers(selectedPayments?.length)
        if (selectedPayments?.length) {
          await piApi.requestSignatures(
            'paymentInstruction',
            selectedPayments.map((pi) => pi.id) || [],
            approvers.map((a) => a.userId)
          )
        }
        if (selectedPayments?.length) {
          await piApi.requestSignatures(
            'paymentInstructionTemplate',
            selectedPayments.map((pi) => pi.id) || [],
            approvers.map((a) => a.userId)
          )
        }
      } catch (error) {
        Sentry.captureException(error)
        throw new Error('Send signature request to approvers error')
      }
    }
  }

  const sendSignatureRequestToNextRound = async (): Promise<void> => {
    const approvers = selectedApprovers.map(
      (
        approver: Approver
      ): {
        userId: string
        email: string
      } => ({
        userId: approver.id,
        email: approver.email,
      })
    )

    if (approveSet && (approveSet.paymentInstructionIds?.length || approveSet.paymentInstructionTemplateIds?.length)) {
      try {
        if (approveSet?.paymentInstructionIds?.length) {
          await piApi.requestSignatures(
            'paymentInstruction',
            approveSet?.paymentInstructionIds || [],
            approvers.map((a) => a.userId)
          )
        }
        if (approveSet?.paymentInstructionTemplateIds?.length) {
          await piApi.requestSignatures(
            'paymentInstructionTemplate',
            approveSet?.paymentInstructionTemplateIds || [],
            approvers.map((a) => a.userId)
          )
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }

  const approvePayments = async (set: Set): Promise<void> => {
    if (!user) {
      return Promise.reject()
    }

    try {
      const approveRequestBody: { email: string; userId: string } = {
        email: user.user.email,
        userId: user.user.id,
      }
      await Promise.all([
        piApi.signPaymentInstructions(set?.paymentInstructionIds || [], approveRequestBody.userId),
        piApi.signPaymentInstructionTemplates(set?.paymentInstructionTemplateIds || [], approveRequestBody.userId),
      ])
    } catch (error) {
      Sentry.captureException(error)
      throw new Error('Approve payments error')
    }
  }

  const refreshRulesData = async () => {
    const response = await rulesApi.getRules()
    setRules(response)
  }

  const submit = async (): Promise<void> => {
    if (isSubmitButtonDisabled) return

    try {
      setIsSubmitButtonDisabled(true)
      if (readySet && (readySet.paymentInstructionIds?.length || readySet.paymentInstructionTemplateIds?.length)) {
        await chargeSetAsync(readySet)
      }

      if (
        requireSignatureSet &&
        (requireSignatureSet.paymentInstructionIds?.length || requireSignatureSet.paymentInstructionTemplateIds?.length)
      ) {
        await piApi.closeSet(requireSignatureSet.id!)
      }

      if (selectedApprovers?.length) {
        await sendSignatureRequestToApprovers()
      }

      if (approveAndPaySet) {
        await approvePayments(approveAndPaySet)

        const newSet: Set = {
          profileId,
          paymentInstructionState: PAYMENT_STATE.READY,
        }

        if (approveAndPaySet.paymentInstructionIds!.length) {
          newSet.paymentInstructionIds = [...approveAndPaySet.paymentInstructionIds!]
        }
        if (approveAndPaySet.paymentInstructionTemplateIds!.length) {
          newSet.paymentInstructionTemplateIds = [...approveAndPaySet.paymentInstructionTemplateIds!]
        }

        const response = await piApi.createSet(newSet)
        await chargeSetAsync(response)
      }

      if (approveSet) {
        await approvePayments(approveSet)
        await piApi.closeSet(approveSet.id!)
      }

      if (approveSet && !!selectedApprovers?.length) {
        await sendSignatureRequestToNextRound()
      }

      await refreshRulesData()

      setNumberOfApprovedPayments(numberOfPaymentsToApprove)
      goToProgressSlide()
    } catch (error) {
      setSubmitError(true)
      Sentry.captureException(error)
    } finally {
      setIsSubmitButtonDisabled(false)
    }
  }

  const debounceSubmit = useDebouncePromise(submit, 100)

  return (
    <React.Fragment>
      {is3DSecureShown && progressData !== undefined && (
        <ThreeDSecureModal
          isVisible={is3DSecureShown}
          redirectData={redirectData}
          progressData={progressData}
          chargeId={chargeId}
        />
      )}
      {!!(numberOfPaymentsToPay || numberOfPaymentsForSignatureRequest || numberOfPaymentsToApprove) && (
        <div className={styles.container} data-testid="submit-payments-container">
          <Button
            className="bh-track-make-payment-button"
            type="primary"
            size="large"
            block
            disabled={isSubmitButtonDisabled}
            onClick={() => void debounceSubmit()}
            style={{ height: 'initial' }}
          >
            {!!numberOfPaymentsToPay && (
              <div>
                <span>
                  {intl.formatMessage(pi['pi.add.reviewPayments.submit.numberToPay'], { numberOfPaymentsToPay })}
                </span>
              </div>
            )}
            {!!numberOfPaymentsForSignatureRequest && (
              <div>
                <span>
                  {intl.formatMessage(pi['pi.add.reviewPayments.submit.numberForRequestSignature'], {
                    numberOfPaymentsForSignatureRequest,
                  })}
                </span>
              </div>
            )}
            {!!numberOfPaymentsToApprove && !selectedApprovers?.length && (
              <div>
                <span>
                  {intl.formatMessage(pi['pi.add.reviewPayments.submit.numberOfPaymentsToApprove'], {
                    numberOfPaymentsToApprove,
                  })}
                </span>
              </div>
            )}
            {!!numberOfPaymentsToApprove && !!selectedApprovers?.length && (
              <div>
                <span>
                  {intl.formatMessage(pi['pi.add.reviewPayments.submit.numberOfPaymentsToApproveAndRequest'], {
                    numberOfPaymentsToApprove,
                    numberOfSignatureRequests: selectedApprovers.length,
                  })}
                </span>
              </div>
            )}
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}

export default SubmitPayments
